import styled from 'styled-components'

export const VitrineCliente = styled.section`
  background-color: #e4e5f3;
  padding: 50px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  h1 {
    color: var(--primary-color);
    font-size: 26px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
  }
`

export const ListaVitrine = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  max-width: 100%;
  width: 996px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
`

export const ProdutoVitrine = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  height: 275px;
  width: 314px;

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const TituloModeloVitrine = styled.h3`
  color: #939393;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  width: 60%;
`
