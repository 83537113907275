import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import FootIdQrcode from '../../components/FootId/qrcode'
import Desconto from '../../components/FootId/Desconto'
import ResultadoVendedor from '../../components/FootId/Resultado/vendedor'
import BannerFootID from '../../components/FootId/Banner'
import Loading from '../../components/Loading'
import VitrineProdutos from '../../components/FootId/Vitrine'
import { pisadaResultado } from '../../helpers/footId'
import { PISADAS } from '../../constants/footId'
import bannerResultado from '../../assets/images/foot-id/banner-resultado.jpg'

import * as S from './styles'
import { HashidsFactory } from '../../helpers/util'

const ResultadoFootId = ({ location }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (location.state === null) {
      navigate('/foot-id/vendedor')
    } else {
      setLoading(false)
    }
  }, [location])

  if (loading) {
    return <Loading />
  }
  const hashIdData = HashidsFactory.create().decode(location.state.hashid)
  let numeroCalcado, peEsquerdo, peDireito, resultado
  if (hashIdData.length === 2) {
    // teste numeração
    numeroCalcado = hashIdData[1]
  } else if (hashIdData.length === 3) {
    // teste pisada
    peEsquerdo = hashIdData[1]
    peDireito = hashIdData[2]
    resultado = pisadaResultado(PISADAS[peEsquerdo], PISADAS[peDireito])
  }

  return (
    <Layout>
      <SEO
        title="FOOT ID"
        description="Deixe seus pés falarem: Sua pisada pode definir tudo sobre você e a sua corrida"
      />

      <BannerFootID texto="resultados" src={bannerResultado} />

      <S.ResultadoContent>
        <Link to="/foot-id/vendedor" className="voltar">
          <FaArrowLeft />
          voltar
        </Link>
        {resultado && (
          <ResultadoVendedor
            resultado={resultado}
            peEsquerdo={peEsquerdo}
            peDireito={peDireito}
          />
        )}
        {numeroCalcado && <p>Resultado número calçado {numeroCalcado}</p>}
        {location.state.desconto > 0 && (
          <Desconto desconto={location.state.desconto} />
        )}
      </S.ResultadoContent>

      {resultado && <VitrineProdutos resultado={resultado} />}

      <S.VitrineVendedor>
        <S.Qrcode>
          <FootIdQrcode hashid={location.state.hashid} />
          <h3>
            Utilize o qrcode para levar <br /> este resultado com você
          </h3>
        </S.Qrcode>
      </S.VitrineVendedor>
    </Layout>
  )
}

ResultadoFootId.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ResultadoFootId

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
