import React from 'react'
import PropTypes from 'prop-types'

import { ThumbResultadoPisada } from '../../../helpers/footId'

import * as S from './styles'

const ResultadoVendedor = ({ resultado, peEsquerdo, peDireito }) => {
  return (
    <>
      <S.ResultadoWrapper>
        <S.ResultadoThumb>
          <img
            src={ThumbResultadoPisada(peEsquerdo, peDireito)}
            alt={resultado.titulo}
          />
        </S.ResultadoThumb>
        <span>
          <S.ResultadoTitulo
            dangerouslySetInnerHTML={{ __html: resultado.titulo }}
          />
          <S.ResultadoTexto
            dangerouslySetInnerHTML={{ __html: resultado.descricao }}
          />
        </span>
      </S.ResultadoWrapper>
      <S.ResultadoTexto
        className="texto-adicional"
        dangerouslySetInnerHTML={{
          __html: resultado?.descricao_adicional,
        }}
      />
    </>
  )
}

ResultadoVendedor.propTypes = {
  resultado: PropTypes.object.isRequired,
  peEsquerdo: PropTypes.number.isRequired,
  peDireito: PropTypes.number.isRequired,
}

export default ResultadoVendedor
