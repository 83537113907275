import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'

const Desconto = ({ desconto }) => (
  <>
    <S.Desconto>
      <p>
        você acaba de receber
        <span> {desconto}% de desconto</span>
        para a sua próxima compra*
      </p>
    </S.Desconto>
    <S.ValidadeDesconto>
      *Desconto válido para compras no local após o teste.
    </S.ValidadeDesconto>
  </>
)

Desconto.propTypes = {
  desconto: PropTypes.number.isRequired,
}

export default Desconto
