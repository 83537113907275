import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import logo from '../../../assets/images/asics.png'

export const FootIdQrcode = ({ hashid }) => {
  return (
    <QRCode
      style={{ margin: '5px' }}
      value={`${window.location.origin}/foot-id/r#${hashid}`}
      bgColor={'#ffffff'}
      level={'H'}
      renderAs={'svg'}
      imageSettings={{
        src: logo,
        excavate: true,
        width: 60,
        height: 20.28,
      }}
    />
  )
}

FootIdQrcode.propTypes = {
  hashid: PropTypes.string.isRequired,
}

export default FootIdQrcode
