import styled from 'styled-components'

import bgComoRealizar from '../../assets/images/foot-id/bg-como-realizar.jpg'

export const ComoRealizar = styled.section`
  align-items: center;
  background-image: url(${bgComoRealizar});
  background-size: cover;
  background-position: center top;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 697px;
  justify-content: center;
  width: 100%;

  @media (max-width: 767px) {
    height: auto;
    padding: 30px 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: auto;
    padding: 30px 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: auto;
    padding: 30px 0;
  }

  h1 {
    font-size: 50px;
    font-style: italic;
    font-weight: 400;
    line-height: 40px;

    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 50px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 40px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  h2 {
    font-size: 25px;
    font-style: italic;
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
`

export const WrapperRealizar = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 40px auto;
  max-width: 100%;
  width: 1170px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 15px;
  }
`

export const CardRealizar = styled.article`
  background-color: var(--primary-color);
  height: 370px;
  padding: 30px 0;
  width: 340px;

  @media (max-width: 767px) {
    height: auto;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: 310px;
  }
`

export const IconeRealizar = styled.figure`
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  height: 130px;
  justify-content: center;
  margin: 0 auto;
  width: 130px;
`

export const ContentRealizar = styled.div`
  padding: 20px;
  width: 100%;

  p {
    font-size: 23px;
    font-style: italic;
    font-weight: 400;
    line-height: 28px;
    text-align: center;

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 20px;
    }
  }
`

export const Lojas = styled.section`
  padding-top: 50px;
  width: 100%;

  h1 {
    color: var(--primary-color);
    font-size: 40px;
    font-style: italic;
    font-weight: 400;
    line-height: 35px;
    text-align: center;

    @media (min-width: 1200px) {
      font-size: 50px;
    }
  }
`

export const LojasWrapper = styled.div`
  margin: 40px auto;
  max-width: 100%;
  text-align: center;
  width: 1170px;

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 15px;
  }

  .swiper-container {
    margin-bottom: 30px;
  }

  .swiper-pagination {
    bottom: 0;
  }
`

export const Agendamento = styled.section`
  background-color: #e4e5f3;
  padding: 60px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 40px 15px;
  }
`

export const FormAgendamento = styled.form`
  margin: 0 auto;
  max-width: 100%;
  width: 975px;

  h1 {
    border-bottom: 2px dotted #56a5c7;
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 40px;
    padding: 0 15px;
    text-transform: uppercase;
  }

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 26px;
    padding: 0 15px;
    text-transform: uppercase;
  }

  h3 {
    border-bottom: 2px dotted #56a5c7;
    color: var(--primary-color);
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    padding: 0 15px;
    text-transform: uppercase;
  }
`

export const WrapperFormAgendamento = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin: 40px 0;
  width: 100%;

  .item {
    width: 48%;

    &--full {
      width: 100%;
    }

    &--duplo {
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
      }
    }

    &--busca {
      span {
        display: flex;
        gap: 10px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      width: 45%;
    }
  }

  .id_cliente {
    width: 100%;

    @media (min-width: 991px) {
      width: 40%;
    }
  }
`

export const SucessoAgendamento = styled.section`
  background-color: #fff;
  border-radius: 30px;
  margin: 30px auto;
  max-width: 100%;
  padding: 50px;
  text-align: center;
  width: 1000px;

  @media (max-width: 767px) {
    padding: 20px 15px;
  }

  h1 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 32px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  h2 {
    color: var(--primary-color);
    font-family: 'ASICSFont3.0-BoldItalic';
    font-size: 22px;
  }

  p {
    color: var(--primary-color);
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    margin: 20px auto;
    width: 80%;

    @media (max-width: 767px) {
      font-size: 18px;
      width: 100%;
    }
  }
`

export const EnderecoSucesso = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 30px auto 10px auto;
  text-align: left;
  width: 580px;

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  p {
    font-size: 19px;
    line-height: 25px;
    margin: 0;
    width: 100%;
  }

  a {
    display: inline-block;
    margin-top: 10px;
  }
`

export const MensagemHorarioFuncionamento = styled.p`
  color: var(--primary-color);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  text-align: right;
`

export const Upload = styled.div`
  align-items: center;
  border-radius: 1.875rem;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  height: 3.125rem;
  padding: 0 20px;
  position: relative;
  width: 100%;

  input[type='file'] {
    display: none;
  }
`

export const BotaoUpload = styled.span`
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 1.875rem;
  color: #fff;
  display: flex;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 18px;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 200px;
`

export const ResultadoContent = styled.section`
  padding: 50px 0;
  margin: 0 auto;
  max-width: 100%;
  width: 1170px;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 30px 15px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 50px 15px;
  }

  .voltar {
    align-items: center;
    color: var(--primary-color);
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: var(--secondary-color);
    }
  }
`

export const VitrineVendedor = styled.section`
  background-color: #e4e5f3;
  padding-bottom: 50px;
  width: 100%;

  @media (max-width: 767px) {
    padding: 30px 15px;
  }
`

export const Qrcode = styled.div`
  align-items: center;
  border-radius: 25px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 510px;
  margin: 0 auto;
  padding: 30px;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }

  h3 {
    color: var(--primary-color);
    font-size: 26px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 30px;

    @media (max-width: 767px) {
      margin-top: 15px;
    }
  }
`
