import styled from 'styled-components'

export const Desconto = styled.div`
  background-color: var(--secondary-color);
  color: #fff;
  margin: 20px 0 5px 0;
  padding: 20px;
  width: 100%;

  p {
    align-items: center;
    display: flex;
    font-style: italic;
    font-size: 24px;
    gap: 15px;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 5px;
      text-align: center;
    }
  }

  span {
    font-size: 40px;
    font-weight: 400;

    @media (max-width: 767px) {
      font-size: 37px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 30px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 40px;
    }
  }
`

export const ValidadeDesconto = styled.p`
  color: var(--primary-color);
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
`
