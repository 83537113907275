import React from 'react'
import PropTypes from 'prop-types'

import {
  cumulus23,
  cumulus23f,
  dynablast,
  fujitrabuco9,
  gt1000,
  gt2000,
  kayano27,
  kayano28,
  kayano28mk,
  metaspeed,
  nimbus23,
  novablast2,
} from '../../../images/foot-id/vitrine'

import * as S from './styles'

function ThumbVitrine(modelo) {
  const THUMBS = {
    cumulus23,
    cumulus23f,
    dynablast,
    fujitrabuco9,
    gt1000,
    gt2000,
    kayano27,
    kayano28,
    kayano28mk,
    metaspeed,
    nimbus23,
    novablast2,
  }

  return THUMBS[modelo]
}

const VitrineProdutos = ({ resultado }) => {
  return (
    <S.VitrineCliente>
      <h1>Descubra os principais modelos para a sua pisada:</h1>
      <S.ListaVitrine>
        {resultado.vitrine.map((item, index) => (
          <S.ProdutoVitrine key={`${item.modelo}-${index}`}>
            <img src={ThumbVitrine(item.thumb)} alt={item.modelo} />
            <S.TituloModeloVitrine>{item.modelo}</S.TituloModeloVitrine>
          </S.ProdutoVitrine>
        ))}
      </S.ListaVitrine>
    </S.VitrineCliente>
  )
}

VitrineProdutos.propTypes = {
  resultado: PropTypes.object.isRequired,
}

export default VitrineProdutos
